import React, { SVGAttributes } from 'react';

const Accommodations = (props: SVGAttributes<SVGElement>) => (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g
            id="accomodations"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <g id="Group" transform="translate(1.000000, 1.000000)" stroke="currentColor" strokeWidth="1.5">
                <path
                    d="M12.5,6.875 C12.845,6.875 13.125,7.1548 13.125,7.5 C13.125,7.8452 12.845,8.125 12.5,8.125 C12.155,8.125 11.875,7.8452 11.875,7.5 C11.875,7.1548 12.155,6.875 12.5,6.875"
                    id="Path"
                />
                <path
                    d="M12.5,7.10542736e-15 C16.642,7.10542736e-15 20,3.3579 20,7.5 C20,10.7017 15.52,16.9167 13.485,19.52 C13.248,19.8232 12.885,20.0004 12.5,20.0004 C12.115,20.0004 11.752,19.8232 11.515,19.52 C9.48,16.9167 5,10.7017 5,7.5 C5,3.3579 8.358,7.10542736e-15 12.5,7.10542736e-15 Z"
                    id="Path"
                />
                <path
                    d="M5,27.5 L5,35 C5,36.381 6.119,37.5 7.5,37.5 L17.5,37.5 C18.881,37.5 20,36.381 20,35 L20,27.5 M5.555,20 L-1.13686838e-13,25 M24.998,25 L19.441,19.9983"
                    id="Combined-Shape"
                />
                <path
                    d="M35,27.9167 L35,36.25 C35,36.94 34.44,37.5 33.75,37.5 L25,37.5 M30,23.75 L37.5,30"
                    id="Combined-Shape"
                />
            </g>
        </g>
    </svg>
);

export default Accommodations;
