import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Shield from './icons/usp/Shield';
import styled from 'styled-components';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box } from '@rebass/grid';
import Accommodations from './icons/usp/Accommodations';
import Tag from './icons/usp/Tag';
import Local from './icons/usp/Local';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import AppIcon from './icons/AppIcon';
import TexelPassport, { texelAppLink } from './TexelPassport';
import { useRouter } from 'next/router';
import Link from './Link';

interface USPItem {
    title: ReactNode;
    icon: ReactNode;
    link?: (locale: string) => string;
}

const usps: USPItem[] = [
    {
        title: <FormattedMessage defaultMessage="Veilig betalen" />,
        icon: <Shield />,
    },
    {
        title: (
            <FormattedMessage
                defaultMessage="@texel app {br} Korting bij Texelse ondernemers"
                values={{
                    br: <br />,
                }}
            />
        ),
        icon: <AppIcon width={40} height={40} />,
        link: locale => texelAppLink[locale as 'nl' | 'de' | 'en'],
    },
    {
        title: <FormattedMessage defaultMessage="Meeste accommodaties" />,
        icon: <Accommodations />,
    },
    {
        title: <FormattedMessage defaultMessage="Beste prijs" />,
        icon: <Tag />,
    },
    {
        title: <FormattedMessage defaultMessage="Wij wonen er zelf" />,
        icon: <Local />,
    },
];

const Grid = styled.ul`
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3.6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-template-columns: repeat(${usps.length}, 1fr);
        grid-gap: 8.2rem;
    }
`;

interface Props {
    hidePassport?: boolean;
}

const USPs = ({ hidePassport = false }: Props) => {
    const { locale } = useRouter();
    return (
        <>
            {!hidePassport && (
                <ContentWrapper>
                    <TexelPassport />
                </ContentWrapper>
            )}
            <ContentWrapper>
                <Box my={[4, 8]} py={[4, 8]} px={[7, 0]} as={Grid}>
                    {usps.map(({ icon, title, link }, i) => (
                        <React.Fragment key={i}>
                            {link ? (
                                <Link href={link(locale ?? 'nl')}>
                                    <UnstyledLink>
                                        <USP key={i}>
                                            {icon}
                                            <Box mt={[0, 0, 5]}>
                                                <Body variant="small">{title}</Body>
                                            </Box>
                                        </USP>
                                    </UnstyledLink>
                                </Link>
                            ) : (
                                <USP>
                                    {icon}
                                    <Box mt={[0, 0, 5]}>
                                        <Body variant="small">{title}</Body>
                                    </Box>
                                </USP>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            </ContentWrapper>
        </>
    );
};

const UnstyledLink = styled.a`
    text-decoration: none;
    transition: 0.15s color;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.primary[50]};
    }
`;

const USP = styled.li`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    margin-top: ${({ theme }) => theme.spacing['30_Small']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
    }

    p {
        font-weight: 500;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            min-height: 4.8rem;
        }
    }

    &:last-child {
        grid-column: span 2;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
            grid-column: auto;
        }
    }

    svg {
        max-width: 2rem;
        margin-right: 2.1rem;

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-right: 0;
        }

        path,
        circle {
            stroke-width: 3;
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            max-width: unset;

            path,
            circle {
                stroke-width: 2;
            }
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-column: auto;
    }
`;

export default USPs;
