import React, { SVGAttributes } from 'react';

const Local = (props: SVGAttributes<SVGElement>) => (
    <svg width="38" height="39" viewBox="0 0 38 39" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-1345-63H575v248h-1920z" />
            <g transform="translate(-41 1)" strokeLinecap="round" strokeLinejoin="round">
                <g stroke="#223260" strokeWidth="1.5">
                    <path d="M50 15.25v10c0 1.534 1.343 2.778 3 2.778h14c1.657 0 3-1.244 3-2.778v-10M75 12.75L60 .25l-15 12.5M77.5 36.5c-5.487-3.563-10.269-5.028-17.5-5-7.231.029-12.013 1.437-17.5 5" />
                    <path d="M60 20.25l-4.261-4.233a2.318 2.318 0 01-.473-2.772 2.514 2.514 0 011.851-1.296c.8-.124 1.614.126 2.187.672l.696.662.696-.663a2.602 2.602 0 012.187-.672c.8.123 1.489.606 1.851 1.296.485.924.295 2.04-.473 2.772L60 20.25zM65 .25h5v5" />
                </g>
            </g>
        </g>
    </svg>
);

export default Local;
