import React, { SVGAttributes } from 'react';

const Tag = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
        <g
            id="tag"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M16.9124185,27.2500047 C13.6124185,23.9583047 13.6124185,20.3233047 16.9124185,17.0317047 C20.2024185,13.7400047 23.8324185,13.7400047 27.1224185,17.0317047 M16.9124185,13.6267047 L23.7224185,20.4383047 M13.5024185,17.0317047 L18.6124185,22.1400047"
                id="Combined-Shape"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <circle id="Oval" stroke="currentColor" strokeWidth="1.5" cx="30.1624185" cy="8.83330468" r="2.40833" />
            <path
                d="M35.9224185,1 L20.3024185,1 C19.5924185,1 18.9124185,1.29340468 18.4224185,1.81170468 L1.70241846,19.5967047 C1.22241846,20.1037047 0.972418457,20.7834047 1.00241846,21.4790047 C1.04241846,22.1747047 1.35241846,22.8262047 1.88241846,23.2833047 L18.6024185,37.8650047 C19.6424185,38.7760047 21.2224185,38.6970047 22.1724185,37.6850047 L37.8124185,20.9900047 C38.2524185,20.5153047 38.5024185,19.8891047 38.5024185,19.2383047 L38.5024185,3.56670468 C38.5024185,2.88480468 38.2324185,2.23120468 37.7424185,1.74980468 C37.2624185,1.26840468 36.6124185,0.998704678 35.9224185,1 L35.9224185,1 Z"
                id="Path"
                stroke="currentColor"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

export default Tag;
