import * as React from 'react';
import { SVGAttributes } from 'react';

const AppIcon = (props: SVGAttributes<SVGElement>) => (
    <svg width="24" height="37" viewBox="0 0 24 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_1814_9384)">
            <path d="M23 29.9131H1" stroke="#223260" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M12 33.337C11.7941 33.337 11.6271 33.1667 11.6271 32.9565C11.6271 32.7464 11.7941 32.5761 12 32.5761"
                stroke="#223260"
                strokeWidth="1.5"
            />
            <path
                d="M12 33.337C12.2059 33.337 12.3729 33.1667 12.3729 32.9565C12.3729 32.7464 12.2059 32.5761 12 32.5761"
                stroke="#223260"
                strokeWidth="1.5"
            />
            <path
                d="M20.0169 1H3.98305C2.33556 1 1 2.36261 1 4.04348V32.9565C1 34.6374 2.33556 36 3.98305 36H20.0169C21.6645 36 23 34.6374 23 32.9565V4.04348C23 2.36261 21.6645 1 20.0169 1Z"
                stroke="#223260"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.017 8.42603H6.03394C5.21021 8.42603 4.54242 9.10732 4.54242 9.94776V12.9912C4.54242 13.8317 5.21021 14.513 6.03394 14.513H9.017C9.84073 14.513 10.5085 13.8317 10.5085 12.9912V9.94776C10.5085 9.10732 9.84073 8.42603 9.017 8.42603Z"
                stroke="#223260"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9662 8.42603H14.9831C14.1594 8.42603 13.4916 9.10732 13.4916 9.94776V12.9912C13.4916 13.8317 14.1594 14.513 14.9831 14.513H17.9662C18.7899 14.513 19.4577 13.8317 19.4577 12.9912V9.94776C19.4577 9.10732 18.7899 8.42603 17.9662 8.42603Z"
                stroke="#223260"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.017 17.5565H6.03394C5.21021 17.5565 4.54242 18.2378 4.54242 19.0783V22.1217C4.54242 22.9622 5.21021 23.6435 6.03394 23.6435H9.017C9.84073 23.6435 10.5085 22.9622 10.5085 22.1217V19.0783C10.5085 18.2378 9.84073 17.5565 9.017 17.5565Z"
                stroke="#223260"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.9662 17.5565H14.9831C14.1594 17.5565 13.4916 18.2378 13.4916 19.0783V22.1217C13.4916 22.9622 14.1594 23.6435 14.9831 23.6435H17.9662C18.7899 23.6435 19.4577 22.9622 19.4577 22.1217V19.0783C19.4577 18.2378 18.7899 17.5565 17.9662 17.5565Z"
                stroke="#223260"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1814_9384">
                <rect width="24" height="37" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default AppIcon;
