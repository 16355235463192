import React, { SVGAttributes } from 'react';

const Shield = (props: SVGAttributes<SVGElement>) => (
    <svg width="38" height="40" viewBox="0 0 38 40" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-537-64h1920v248H-537z" />
            <g transform="translate(-41 1)" strokeLinecap="round" strokeLinejoin="round">
                <g fillRule="nonzero" stroke="#223260" strokeWidth="1.5">
                    <path d="M51.25 17.422l4.083 5.801a1.75 1.75 0 002.845.085L71.25 6.763" />
                    <path d="M45 .248a2.5 2.5 0 00-2.5 2.5v15c0 8.022 12.057 16.949 16.155 19.6a2.462 2.462 0 002.69 0C65.443 34.697 77.5 25.77 77.5 17.748v-15a2.5 2.5 0 00-2.5-2.5H45z" />
                </g>
            </g>
        </g>
    </svg>
);

export default Shield;
